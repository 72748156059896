@import "~antd/dist/antd.css";
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@300;400;500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Varela+Round&display=swap");

body {
  background: #fafafa;
  font-family: "Noto Sans TC", sans-serif;
}

.float-shadow {
  background-color: #fff;
  box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.drawer-area {
  .ant-drawer-header,
  .ant-drawer-body {
    background-color: #f0f5ff;
  }

  .ant-drawer-header {
    border-bottom: 1px solid #d9d9d9;
  }
}
