.worker-list-pdf-area {
  width: 100%;
  padding: 10px;

  font-family: "Noto Sans TC", sans-serif;
  font-weight: 500;

  .print-btn {
    position: absolute;
    right: 10px;
    top: 10px;

    Button {
      box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.1);
      border-radius: 32px;
      margin-left: 10px;
    }

    @media print {
      .print-btn {
        display: none;
      }
    }
  }

  .title {
    margin-top: 30px;
    margin-bottom: 10px;
    font-size: 20px;
    text-align: center;
  }

  .item {
    padding: 20px;
    margin-top: 20px;
    .info {
      width: 100%;
      display: inline-flex;
      flex-wrap: nowrap;
      // justify-content: space-between;
      font-size: 15.8px;
      // margin-top: 30px;
      // margin-bottom: 50px;
    }

    .site-name {
      font-size: 30px;
    }

    .info-table {
      width: 500px;
      // border: 2px solid red;

      td {
        width: 90px;
        font-family: "Noto Sans TC", sans-serif;
        font-weight: 500;
        font-size: 15px;
        padding-top: 8px;
      }
      margin-bottom: 28px;
    }

    .image-container {
      // width: 80px;
      // height: 80px;
    }
  }
}

@page {
  size: A4 portrait;
  margin: 0.5cm;
  orphans: 4;
  widows: 2;
}

@media print {
  .print-btn {
    display: none;
  }
}
