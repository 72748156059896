$a: 100vw;
$b: 20px;
$width: calc(#{$a} - #{$b});

.safety-rules-sign-list-comp {
  width: 100vw;
  min-height: 100vh;
  background-color: #fafafa;
  overflow-x: hidden;
  padding: 10px;

  // nav bar
  .nav-bar-area {
    width: 100%;
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border-radius: 32px;
    background-color: #fff;
    border: 1px solid #fafafa;

    .cir-logo-area {
      //   border: 2px solid red;
      //   margin-top: -8px;
      margin-bottom: 4px;
      .cir-logo-area {
        width: 240px;
        height: 40px;

        img {
          width: 240px;
          height: 40px;
          object-fit: contain;
        }
      }
    }
    .btn-area {
      Button {
        margin-left: 10px;

        &:nth-child(1) {
          color: #bfbfbf;
        }
      }
    }

    /*如果螢幕寬度為 1028px 以下，就套用 css 設定*/
    @media screen and (max-width: 1028px) {
      .btn-area {
        display: none;
      }
    }
  }

  //safety rules bg
  .safety-rules-bg-area {
    width: $width;
    height: 23vw;
    background-color: #fff;
    box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.1);
    border-radius: 32px;
    margin-top: 16px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .title {
      position: absolute;
      margin-top: -20vw;
      margin-left: 38.8vw;
      opacity: 1;
      h1 {
        color: #fafafa;
        font-family: "Noto Sans TC", sans-serif;
        font-weight: 500;
        font-size: 3.8vw;
        letter-spacing: 8px;
      }
    }

    /*如果螢幕寬度為 1028px 以下，就套用 css 設定*/
    @media screen and (max-width: 1028px) {
      height: 180px;
      .title {
        margin-top: -138px;
        margin-left: 30vw;
        h1 {
          font-size: 20px;
          letter-spacing: 8px;
        }
      }
    }
  }

  // search bar area
  .search-bar-area {
    width: $width;
    height: 50px;
    border-radius: 32px;
    display: inline-flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    margin-top: -30px;

    .search-bar-container {
      // position: absolute;
      padding: 20px 15px 20px 4px;
      background-color: #fff;
      box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.1);
      border-radius: 15px;
      display: inline-flex;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      .search-item {
        // border: 2px solid red;
        width: 18vw;
        padding: 0px 8px;
        .ant-picker {
          width: 100%;
        }

        .ant-select {
          width: 100%;
          font-size: 18.8px;
        }

        .ant-picker-input > input {
          font-size: 18.8px;
        }

        .ant-input {
          font-size: 18.8px;
        }

        &:nth-child(1),
        &:nth-child(2) {
          //   border-right: 1px solid #bfbfbf;
        }

        &:nth-child(5) {
          //   width: 158px;
          width: 180px;
          .ant-btn {
            font-size: 15px;
            width: 100%;
            height: 50px;
          }
        }
      }
    }

    /*如果螢幕寬度為 1028px 以下，就套用 css 設定*/
    @media screen and (max-width: 1028px) {
      width: 100%;
      margin-top: 100px;
      border-radius: 32px;
      background-color: #fff;
      box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.1);

      .search-bar-container {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
        border-radius: 32px;

        .search-item {
          width: 100%;
          margin-top: 4px;

          &:nth-child(4) {
            //   width: 158px;
            width: 100%;
            .ant-btn {
              font-size: 15px;
              width: 100%;
              height: 50px;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }

  //table area
  .table-area {
    width: $width;
    margin-top: 38px;
    background-color: #fff;
    box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.1);
    border-radius: 32px;
    overflow: hidden;
    border: 2px solid #f0f0f0;
    font-family: "Varela Round", "Noto Sans TC", sans-serif;
    font-weight: 400;
    overflow-x: scroll;

    .ant-table-thead {
      border-radius: 15px;
      background-color: red;
    }

    .ant-table {
      color: #2f54eb;
      font-size: 18px;
      min-width: 1500px;
    }

    th.ant-table-cell {
      // background-color: #597ef7;
      background-color: #2f54eb;
      color: #fff;
    }

    .red-text {
      color: #eb2f96;
    }

    .green-text {
      color: #52c41a;
    }

    /*如果螢幕寬度為 1028px 以下，就套用 css 設定*/
    @media screen and (max-width: 1028px) {
      margin-top: 100px;
      width: 100%;
      overflow-x: scroll;
      .ant-table {
        width: 1500px;
      }
    }
  }

  .skeleton-area {
    padding: 10px;
    margin-top: 30px;
  }
}

// ----
.signed-worker-list-area {
  width: 100%;
  height: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .signed-worker-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin-bottom: 16px;
    background-color: #fff;
    box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    .signed-worker-item-upper {
      width: 100%;
      border: 2px soild red;
      border-radius: 10px;
      overflow: hidden;
      margin: 5px 0px 5px 0px;

      img {
        width: 100%;
        object-fit: cover;
      }
    }

    .signed-worker-item-down {
      width: 100%;
      display: inline-flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      background-color: #fff;
      margin-top: 10px;
      // border-bottom: 2px solid #f0f0f0;

      .left {
        padding: 10px;
        .worker-avatar {
          width: 58px;
          height: 58px;
          border-radius: 100px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      .middle {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin-left: 8px;
        font-family: "Noto Sans TC", sans-serif;
        font-weight: 400;

        h3 {
          font-size: 18px;
        }
        .worker-id {
          margin-top: -8px;
        }
        .date-create {
          color: #8c8c8c;
          margin-top: -8px;
        }

        .goldStar {
          color: gold;
          margin-right: 8px;
        }
      }

      .right {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 22.8px;
        color: #73d13d;
        padding: 10px;
      }
    }
  }
}
#jsx-template {
  color: red;
  border: 2px solid red;
}

#to-print-area {
  .green-check-icon {
    color: #73d13d;
  }

  .gray-check-icon {
    color: #d9d9d9;
  }
}
@media print {
  #to-print-area {
    color: green;
    border: 2px solid green;
  }
}
